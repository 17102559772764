exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bars-healing-tsx": () => import("./../../../src/pages/bars-healing.tsx" /* webpackChunkName: "component---src-pages-bars-healing-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kiflow-tsx": () => import("./../../../src/pages/kiflow.tsx" /* webpackChunkName: "component---src-pages-kiflow-tsx" */),
  "component---src-pages-massage-tsx": () => import("./../../../src/pages/massage.tsx" /* webpackChunkName: "component---src-pages-massage-tsx" */),
  "component---src-pages-samtal-tsx": () => import("./../../../src/pages/samtal.tsx" /* webpackChunkName: "component---src-pages-samtal-tsx" */),
  "component---src-pages-zonterapi-tsx": () => import("./../../../src/pages/zonterapi.tsx" /* webpackChunkName: "component---src-pages-zonterapi-tsx" */)
}

